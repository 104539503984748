import * as React from 'react';
import { useDispatch } from 'react-redux';

import { alpha } from '@mui/system';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Slide, { SlideProps } from '@mui/material/Slide';
import { Undo } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

import { StyledUndoAction, StyledSnackbar } from './styles';

import { removeToast } from 'store/app/reducer';
import { Toast } from 'store/app/interface';

type ToastsProps = {
  toast: Toast | null;
  autoDismiss?: boolean;
  delay?: number;
  withUndo?: boolean;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Snackbar(props: ToastsProps) {
  const { toast, delay = 5000, withUndo = false } = props;
  const dispatch = useDispatch();

  const dismissToast = () => {
    dispatch(removeToast());
  };

  const undoToast = () => {
    if (toast?.onUndo) {
      toast?.onUndo();
    }
    dispatch(removeToast());
  };

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(removeToast());
    }, 5000);
  }, []);

  const action = (
    <React.Fragment>
      {withUndo && (
        <StyledUndoAction color="primary" size="small" onClick={undoToast}>
          <span>Undo</span>
          <Undo fontSize="small" />
        </StyledUndoAction>
      )}
      <IconButton size="small" aria-label="close" color="inherit" onClick={dismissToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const transition = (props: SlideProps) => {
    return <Slide {...props} direction="up" />;
  };

  return (
    <StyledSnackbar
      open={!!toast?.message}
      autoHideDuration={delay}
      onClose={dismissToast}
      TransitionComponent={transition}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}>
      <Alert
        onClose={dismissToast}
        severity={(toast?.type || 'success') as AlertColor}
        action={action}
        sx={(theme) => {
          if (!withUndo) return { minWidth: '360px' };

          const severityType = (toast?.type || 'success') as AlertColor;
          const severityColor = theme.palette[severityType].main;

          return {
            backgroundColor: alpha(severityColor, 0.9),
            minWidth: '360px'
          };
        }}>
        {toast?.message || ''}
      </Alert>
    </StyledSnackbar>
  );
}
