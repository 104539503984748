import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

export const StyledUndoAction = styled(Button)`
  padding-top: 4px;
  margin-right: 6px;
  height: 32px;
  text-transform: none;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  background-color: ${({ theme }) => theme.colors.gray.light};
  border: 1px solid ${({ theme }) => theme.colors.gray.medium};

  span {
    font-size: 14px;
    margin-right: 6px;
  }

  svg {
    font-size: 16px;
  }

  box-shadow: 1px 1px 4px ${({ theme }) => theme.colors.gray.average};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray.medium};
    border-color: ${({ theme }) => theme.colors.gray.opaque};
    color: ${({ theme }) => theme.colors.blue};
  }

  &:active {
    transform: translateY(2px);
  }
`;

export const StyledSnackbar = styled(Snackbar)`
  padding: 15px;
  border-radius: 0;
  text-align: center;

  .MuiAlert-icon {
    padding: 9px 0px 8px 0px;
  }

  .MuiAlert-message {
    padding: 10px 0px 8px 0px;
  }
`;
